<template>
  <div style="border: 1px solid #eee;">
    <NCard
      v-show="!!editingSection.key"
      size="small"
      :bordered="false"
      title="编辑章节内容"
      :segmented="{ content: 'hard' }"
    >
      <div v-if="Array.isArray(editingSection.material_section_type_list)">
        <SectionModule
          v-for="(moduleItem, index) in editingSection.material_section_type_list" :key="index"
          :ref="setSectionModuleRef"
          :read-only="editingSection.readOnly"
          :module-data="moduleItem"
          :modules-num="editingSection.material_section_type_list.length"
          @add-teaching-click="handleClickAddTeaching"
          @add-exercises-click="handleClickAddExercises"
          @exercises-edit-click="handleClickEditExercises"
          @resource-del-click="handleClickDelResource"
          @module-del-click="handleClickDelModule"
        />
        <NButton block @click="addModule" v-show="!editingSection.readOnly">
          <template #icon>
            <NIcon>
              <PlusOutlined />
            </NIcon>
          </template>
          添加模块
        </NButton>
      </div>
    </NCard>
    <div v-show="!editingSection.key" style="padding: 12px 16px; color: #999;">
      <span>请选择节进行编辑</span>
    </div>

    <AddExercisesModal
      ref="addExercisesModalRef"
      @exercise-id-add="handleAddExerciseIds"
    />
    <AddTeachingsModal
      ref="addTeachingsModalRef"
      @teaching-add="handleAddTeachings"
    />
  </div>
</template>

<script setup>
  import { ref, onBeforeUpdate } from 'vue';
  import { PlusOutlined } from '@vicons/material';

  import SectionModule from './SectionModule.vue';
  import AddExercisesModal from '@/components/AddExercisesModal/AddExercisesModal.vue';
  import AddTeachingsModal from '@/components/AddTeachingsModal/AddTeachingsModal.vue';

  import {
    generateModuleItem,
    generateResourceItem
  } from './tool-fns.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';

  const props = defineProps({
    editingSection: {
      type: Object,
      default: () => {}
    }
  });

  const addExercisesModalRef = ref(null);
  const addTeachingsModalRef = ref(null);

  let addingResourceModule;
  const handleClickAddTeaching = moduleData => {
    addingResourceModule = moduleData;
    const list = addingResourceModule.type_source_list;
    const filterIds = Array.isArray(list) ?
      list.filter(({ data_type }) => Number(data_type) === resourceEnum.TEACHING_RESOURCE_TYPE).map(({ source_id }) => source_id[0]) :
      [];
    addTeachingsModalRef.value.openModal({
      filterIds
    });
  };
  const handleClickAddExercises = moduleData => {
    addingResourceModule = moduleData;
    editingResourceIndex = -1;
    addExercisesModalRef.value.openModal();
  };

  const handleAddExerciseIds = ids => {
    if (editingResourceIndex === -1) { // 新增习题集
      if (Array.isArray(ids) && ids.length > 0) {
        const seqStr = addingResourceModule.type_source_list.filter(({ data_type }) => Number(data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE).length + 1;
        const exerciseSetTitle = `习题${seqStr}`;
        addingResourceModule.type_source_list.push(
          generateResourceItem(
            {
              data_type: String(resourceEnum.EXERCISES_RESOURCE_TYPE),
              source_id: ids.join(','),
              exerciseSetTitle
            },
            exerciseSetTitle
          )
        );
      }
    } else { // 编辑习题集
      if (Array.isArray(ids) && ids.length > 0) {
        // 替换
        const exerciseSetTitle = addingResourceModule.type_source_list[editingResourceIndex].title;
        addingResourceModule.type_source_list.splice(
          editingResourceIndex,
          1,
          generateResourceItem(
            {
              data_type: String(resourceEnum.EXERCISES_RESOURCE_TYPE),
              source_id: ids.join(','),
              exerciseSetTitle
            },
            exerciseSetTitle
          )
        );
      } else {
        // 删除
        addingResourceModule.type_source_list.splice(editingResourceIndex, 1);
        updateExerciseSetsTitle();
      }
    }

    console.log(editingResourceIndex);
  };
  const handleAddTeachings = list => {
    if (Array.isArray(list) && list.length > 0) {
      list.forEach(({ id, title }) => {
        addingResourceModule.type_source_list.push(
          generateResourceItem(
            {
              data_type: String(resourceEnum.TEACHING_RESOURCE_TYPE),
              source_id: id,
              title
            },
            title
          )
        );
      });
    }
  };

  let editingResourceIndex = -1;
  const updateExerciseSetsTitle = () => {
    let exerciseResourceSeq = 1;
    addingResourceModule.type_source_list.forEach(item => {
      switch (Number(item.data_type)) {
        case resourceEnum.EXERCISES_RESOURCE_TYPE:
          item.title = `习题${exerciseResourceSeq++}`;
          break;
      }
    });
  };
  const handleClickEditExercises = ({ moduleData, resourceItem }) => {
    addingResourceModule = moduleData;
    editingResourceIndex = moduleData.type_source_list.indexOf(resourceItem);

    addExercisesModalRef.value.openModal({
      initIds: resourceItem.source_id
    });
  };
  const handleClickDelResource = ({ moduleData, resourceItem }) => {
    const wrap = moduleData.type_source_list
    wrap.splice(wrap.indexOf(resourceItem), 1);

    if (Number(resourceItem.data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE) {
      updateExerciseSetsTitle();
    }
  };
  const handleClickDelModule = moduleData => {
    const list = props.editingSection.material_section_type_list;
    const index = list.indexOf(moduleData);
    list.splice(index, 1);
  };

  const sectionModuleRefs = [];
  const clearSectionModuleRefs = () => {
    sectionModuleRefs.splice(0);
  };
  const setSectionModuleRef = el => {
    if (el) {
      sectionModuleRefs.push(el);
    }
  };
  onBeforeUpdate(() => {
    clearSectionModuleRefs();
  });

  const addModule = () => {
    clearSectionModuleRefs();

    props.editingSection.material_section_type_list.push(
      generateModuleItem()
    );
  };

  defineExpose({
    cancelAllModuleNameRename: () => {
      sectionModuleRefs.forEach(moduleRef => moduleRef.exportedCancelRename());
    }
  });
</script>