<template>
  <NCard
    title="教材建设"
    :segmented="{ content: 'hard' }"
  >
    <NSteps :current="currentStep" status="process" style="width: 75%; padding: 15px; margin-bottom: 20px;">
      <NStep title="基本信息" />
      <NStep title="教材内容" />
    </NSteps>
    <Step1
      v-show="currentStep === 1"
      ref="step1Ref"
      :material-id="materialId"
    />
    <Step2 ref="step2Ref" v-show="currentStep === 2" />
    <template #footer>
      <NSpace size="large">
        <template v-if="currentStep === 1">
          <NButton size="large" style="width: 120px;" @click="handleClickSaveBack">保存并返回</NButton>
          <NButton size="large" style="width: 120px;" type="primary" @click="handleClickSaveNext">保存并下一步</NButton>
        </template>
        <template v-else-if="currentStep === 2">
          <NButton size="large" style="width: 120px;" @click="preStep">上一步</NButton>
          <NSpace size="small">
            <NButton
              size="large"
              style="width: 84px;"
              type="primary"
              ghost
              tag="a"
              target="_blank"
              href="/full-page/material-preview"
              @click="handlePreview"
            >预览教材</NButton>
            <NButton size="large" style="width: 120px;" type="primary" @click="handleDone">完成</NButton>
          </NSpace>
        </template>
      </NSpace>
    </template>
  </NCard>

  <PageLoading :loading="loading" />
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import Step1 from './components/Step1.vue';
  import Step2 from './components/Step2.vue';

  import {
    getMaterialDetail,
    editMaterial
  } from '@/api/material.js';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { editTypeEnum } from './enum/edit-type-map.js';

  const { SUCCESS } = resStatusEnum;
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  const message = useMessage();

  const currentStep = ref(1);
  const nextStep = () => {
    currentStep.value++;
  };
  const preStep = () => {
    currentStep.value--;
  };

  const materialId = ref(route.query.id);

  const step1Ref = ref(null);
  const step2Ref = ref(null);



  const handleClickSaveBack = () => {
    step1Ref.value.triggerFormValidator().then(reqData => {
      loading.value = true;
      editMaterial(reqData).then(res => {
        if (res.code === SUCCESS) {
          message.success('保存成功');
          router.push('list');
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    });
  };
  const handleClickSaveNext = () => {
    step1Ref.value.triggerFormValidator().then(reqData => {
      loading.value = true;
      editMaterial(reqData).then(res => {
        if (res.code === SUCCESS) {
          message.success('保存成功');
          nextStep();
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    });
  };
  const handlePreview = () => {
    window.localStorage.setItem('MATERIAL_PREVIEW', JSON.stringify(step2Ref.value.getRunningChapters()));
  };
  const handleDone = () => {
    loading.value = true;
    step2Ref.value.cancelAllRename();
    editMaterial({
      'Material[id]': materialId.value,
      'Material[type]': editTypeEnum.CHAPTER_SECTION,
      'Material[chapter]': step2Ref.value.getChapters()
    }).then(res => {
      if (res.code === SUCCESS) {
        message.success('保存成功');
        router.push('list');
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  loading.value = true;
  getMaterialDetail({
    'Material[id]': materialId.value
  }).then(res => {
    if (res.code === SUCCESS) {
      step1Ref.value.initFormValue(res.data);
      step2Ref.value.initData(res.data.chapter);
    }
  }).catch(err => {}).finally(() => {
    loading.value = false;
  });
</script>