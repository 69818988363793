import { resourceEnum } from '@/enumerators/resource-types-map.js';

const resourceResReqMap = {
  data_type: {
    reqKey: 'type',
    default: ''
  },
  source_id: {
    reqKey: 'list',
    default: () => []
  },
  title: {
    reqKey: 'title',
    default: ''
  },
};
const moduleResReqMap = {
  title: {
    reqKey: 'title',
    default: '本模块名称'
  },
  type_source_list: {
    reqKey: 'data',
    default: () => []
  }
};
const sectionResReqMap = {
  title: {
    reqKey: 'section_title',
    default: '本节名称'
  },
  material_section_type_list: {
    reqKey: 'model',
    default: () => []
  }
};
const chapterResReqMap = {
  title: {
    reqKey: 'title',
    default: '本章名称'
  },
  section_list: {
    reqKey: 'section',
    default: () => []
  }
};

export function generateResourceItem(resourceData = {}, title) {
  const tempObj = {};
  Object.keys(resourceResReqMap).forEach(key => {
    const defaultValue = resourceResReqMap[key].default;
    switch (key) {
      case 'source_id':
        switch (Number(resourceData.data_type)) {
          case resourceEnum.TEACHING_RESOURCE_TYPE:
          case resourceEnum.EXERCISES_RESOURCE_TYPE:
            tempObj[key] = resourceData[key].split(',')
            break;
        }
        break;
      case 'title':
        switch (Number(resourceData.data_type)) {
          case resourceEnum.TEACHING_RESOURCE_TYPE:
            tempObj[key] =
              (resourceData.source_info && resourceData.source_info.title) ||
              (resourceData.list && resourceData.list.title) ||
              title ||
              defaultValue;
            break;
          case resourceEnum.EXERCISES_RESOURCE_TYPE:
            tempObj[key] = title || defaultValue;
            break;
        }
        break;
      default:
        tempObj[key] = resourceData[key] || defaultValue;
    }
  });
  return tempObj;
}
export function generateModuleItem(moduleData = {}) {
  const tempObj = {};
  Object.keys(moduleResReqMap).forEach(key => {
    const defaultValue = moduleResReqMap[key].default;
    switch (key) {
      case 'type_source_list':
        if (Array.isArray(moduleData[key]) && moduleData[key].length > 0) {
          const tempList = moduleData[key].map(resourceData => generateResourceItem(resourceData));
          let exerciseResourceIndex = 1;
          tempList.forEach(item => {
            switch (Number(item.data_type)) {
              case resourceEnum.EXERCISES_RESOURCE_TYPE:
                item.title = `习题${exerciseResourceIndex++}`;
                break;
            }
          });
          tempObj[key] = tempList;
        } else {
          tempObj[key] = defaultValue();
        }
        break;
      default:
        tempObj[key] = moduleData[key] || defaultValue;
    }
  });
  return tempObj;
}
export function generateSectionItem(sectionData = {}, sectionIndex = Date.now()) {
  const tempObj = {
    key: `2-${sectionIndex}-${Date.now()}`,
    readOnly: !!sectionData.readOnly
  };
  Object.keys(sectionResReqMap).forEach(key => {
    const defaultValue = sectionResReqMap[key].default;
    switch (key) {
      case 'material_section_type_list':
        if (Array.isArray(sectionData[key]) && sectionData[key].length > 0) {
          tempObj[key] = sectionData[key].map(moduleData => generateModuleItem(moduleData));
        } else {
          tempObj[key] = defaultValue();
          tempObj[key].push(
            generateModuleItem({ title: '讲义' }),
            generateModuleItem({ title: '习题' }),
            generateModuleItem({ title: '资料' }),
          );
        }
        break;
      default:
        tempObj[key] = sectionData[key] || defaultValue;
    }
  });
  return tempObj;
}
export function generateChapterItem(chapterData = {}, chapterIndex = Date.now()) {
  const tempObj = {
    key: `1-${chapterIndex}-${Date.now()}`,
    readOnly: !!chapterData.readOnly
  };
  Object.keys(chapterResReqMap).forEach(key => {
    const defaultValue = chapterResReqMap[key].default;
    switch (key) {
      case 'section_list':
        if (Array.isArray(chapterData[key]) && chapterData[key].length > 0) {
          tempObj[key] = chapterData[key].map((sectionData, sectionIndex) => generateSectionItem(sectionData, `${chapterIndex}${sectionIndex}`));
        } else {
          tempObj[key] = defaultValue();
          tempObj[key].push(generateSectionItem());
        }
        break;
      default:
        tempObj[key] = chapterData[key] || defaultValue;
    }
  });
  return tempObj;
}

export function getChapters(srcData, resReqMapType = 'material') {
  let usedChapterResReqMap;
  let usedSectionResReqMap;
  let usedModuleResReqMap;
  let usedResourceResReqMap;
  switch (resReqMapType) {
    case 'material':
      usedChapterResReqMap = chapterResReqMap;
      usedSectionResReqMap = sectionResReqMap;
      usedModuleResReqMap = moduleResReqMap;
      usedResourceResReqMap = resourceResReqMap;
      break;
    case 'course':
      usedChapterResReqMap = {
        title: {
          reqKey: 'chapter_title'
        },
        section_list: {
          reqKey: 'section_list'
        }
      };
      usedSectionResReqMap = {
        title: {
          reqKey: 'section_title'
        },
        material_section_type_list: {
          reqKey: 'model_list'
        }
      };
      usedModuleResReqMap = {
        title: {
          reqKey: 'title'
        },
        type_source_list: {
          reqKey: 'list'
        }
      };
      usedResourceResReqMap = {
        data_type: {
          reqKey: 'type'
        },
        source_id: {
          reqKey: 'ids'
        }
      };
      break;
  }

  const tempChapters = [];
  srcData.forEach((chapter, chapterIndex) => {
    if (chapter.readOnly) {
      return;
    }
    const tempChapter = {};
    Object.keys(usedChapterResReqMap).forEach(chapterKey => {
      switch (chapterKey) {
        case 'section_list':
          tempChapter[usedChapterResReqMap[chapterKey].reqKey] = chapter[chapterKey].map((section, sectionIndex) => {
            const tempSection = {};
            Object.keys(usedSectionResReqMap).forEach(sectionKey => {
              switch (sectionKey) {
                case 'material_section_type_list':
                  tempSection[usedSectionResReqMap[sectionKey].reqKey] = section[sectionKey].map((moduleData, moduleIndex) => {
                    const tempModule = {};
                    Object.keys(usedModuleResReqMap).map(moduleKey => {
                      switch (moduleKey) {
                        case 'type_source_list':
                          tempModule[usedModuleResReqMap[moduleKey].reqKey] = moduleData[moduleKey].map(resource => {
                            const tempResource = {};
                            Object.keys(usedResourceResReqMap).forEach(resourceKey => {
                              switch (resourceKey) {
                                case 'source_id':
                                  switch (Number(resource.data_type)) {
                                    case resourceEnum.TEACHING_RESOURCE_TYPE:
                                      tempResource[usedResourceResReqMap[resourceKey].reqKey] = resource[resourceKey].join(',');
                                      break;
                                    case resourceEnum.EXERCISES_RESOURCE_TYPE:
                                      tempResource[usedResourceResReqMap[resourceKey].reqKey] = [...resource[resourceKey]];
                                      break;
                                  }
                                  break;
                                default:
                                  tempResource[usedResourceResReqMap[resourceKey].reqKey] = resource[resourceKey];
                              }
                            });
                            return tempResource;
                          });
                          break;
                        default:
                          tempModule[usedModuleResReqMap[moduleKey].reqKey] = moduleData[moduleKey];
                      }
                    });
                    switch (resReqMapType) {
                      case 'course':
                        tempModule.sort = moduleIndex + 1;
                        break;
                    }
                    return tempModule;
                  });
                  break;
                default:
                  tempSection[usedSectionResReqMap[sectionKey].reqKey] = section[sectionKey];
              }
            });
            switch (resReqMapType) {
              case 'course':
                tempSection.sort = sectionIndex + 1;
                break;
            }
            return tempSection;
          });
          break;
        default:
          tempChapter[usedChapterResReqMap[chapterKey].reqKey] = chapter[chapterKey];
      }
    });
    switch (resReqMapType) {
      case 'course':
        tempChapter.sort = chapterIndex + 1;
        break;
    }
    tempChapters.push(tempChapter);
  });

  return tempChapters;
}