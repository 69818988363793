<template>
  <div>
    <NSpace>
      <NH4 prefix="bar">
        <span v-if="!editing">{{ moduleData.title }}</span>
        <NSpace v-else>
          <NInput
            size="small"
            maxlength="5"
            show-count
            clearable
            v-model:value="moduleData.title"
          />
          <NButton text style="margin-top: 5px;" @click="cancelRename">
            <template #icon>
              <ClearOutlined />
            </template>
          </NButton>
          <NButton text style="margin-top: 5px;" @click="confirmRename">
            <template #icon>
              <CheckOutlined />
            </template>
          </NButton>
        </NSpace>
      </NH4>
      <NButton text style="margin-top: -2px;" @click="resourcesShow = !resourcesShow">
        <template #icon v-if="resourcesShow">
          <NIcon size="30">
            <ArrowDropDownOutlined />
          </NIcon>
        </template>
        <template #icon v-else>
          <NIcon size="30">
            <ArrowLeftOutlined />
          </NIcon>
        </template>
      </NButton>
      <NButton v-show="!readOnly" type="primary" text style="margin-top: 7px;" @click="handleClickRename">重命名</NButton>
      <NButton v-show="!readOnly && modulesNum > 1" type="error" text style="margin-top: 7px;" @click="emit('module-del-click', moduleData)">删除</NButton>
    </NSpace>
    <div class="resource-wrap" v-show="resourcesShow">
      <ul
        class="resource-list"
        v-if="Array.isArray(moduleData.type_source_list) && moduleData.type_source_list.length > 0"
      >
        <li
          class="resource-item"
          v-for="(resourceItem, index) in moduleData.type_source_list"
          :key="index"
        >
          <NSpace justify="space-between">
            <span>{{ resourceItem.title }}</span>
            <NSpace v-show="!readOnly">
              <NButton
                v-if="Number(resourceItem.data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE"
                type="primary"
                text
                @click="emit('exercises-edit-click', { moduleData, resourceItem })"
              >编辑</NButton>
              <NButton
                type="error"
                text
                @click="emit('resource-del-click', { moduleData, resourceItem })"
              >删除</NButton>
            </NSpace>
          </NSpace>
        </li>
      </ul>
      <div v-show="!readOnly">
        <NSpace>
          <NButton type="primary" size="small" @click="emit('add-teaching-click', moduleData)">从教学库添加</NButton>
          <NButton type="primary" size="small" @click="emit('add-exercises-click', moduleData)">从习题库添加</NButton>
        </NSpace>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useMessage } from 'naive-ui';
  import { ArrowLeftOutlined, ArrowDropDownOutlined, ClearOutlined, CheckOutlined } from '@vicons/material';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  
  const props = defineProps({
    moduleData: {
      required: true,
      type: Object
    },
    modulesNum: {
      required: true,
      type: Number
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  });
  const emit = defineEmits([
    'add-teaching-click',
    'add-exercises-click',
    'exercises-edit-click',
    'resource-del-click',
    'module-del-click'
  ]);

  const message = useMessage();
  const resourcesShow = ref(true);
  const editing = ref(false);
  let srcTitle = '';
  const handleClickRename = () => {
    srcTitle = props.moduleData.title;
    editing.value = true;
  };
  const cancelRename = () => {
    props.moduleData.title = srcTitle;
    editing.value = false;
  };
  const confirmRename = () => {
    const title = props.moduleData.title.trim();
    if (!title) {
      message.error('请填写模块名称');
    } else if (title.length < 2) {
      message.error('模块名称限制2～5个字符');
    } else {
      editing.value = false;
    }
  };
  const exportedCancelRename = () => {
    if (editing.value) {
      cancelRename();
    }
  };
  
  defineExpose({
    exportedCancelRename
  });
</script>

<style lang="less" scoped>
  .resource-wrap {
    padding: 0 25px 0 15px;
    margin: -6px 0 18px;
  }

  .resource-list,
  .resource-item {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .resource-item {
    line-height: 24px;
    padding: 0 10px;
    margin-bottom: 10px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
</style>